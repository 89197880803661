.team .items {
  transition: 0.5s;
}
.team .img {
  position: relative;
}
.team img {
  width: 100%;
  height: 100%;
}
.overlay {
  position: absolute;
  bottom: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  padding: 20px;
  opacity: 0;
  transition: 0.5s;
}
.overlay i {
  background-color: #000;
  color: #fff;
  margin: 5px;
  transition: 0.5s;
}
.team .details {
  padding: 30px;
  text-align: center;
}
.team .details h2 {
  font-weight: 500;
  font-size: 20px;
  transition: 0.5s;
}
.team .details p {
  font-size: 15px;
  color: grey;
  margin-top: 10px;
  transition: 0.5s;
}
.team .items:hover {
  background-color: #1a3975;
  color: #fff;
  cursor: pointer;
}
.team .items:hover p {
  color: #fff;
}
.team .items:hover .overlay {
  opacity: 1;
}
.team .items:hover .overlay i:hover {
  background-color: #1a3975;
  color: #fff;
}
::-webkit-scrollbar{
  display: none;
}
.something{
  background-color: rgba(0, 0, 0, 0.2);
  /* position: absolute; */
  margin: -200px auto;
  padding: 20px;
  width: 600px;
  border-radius: 20px;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}
.back-team {
  background-image: url("../../../public/images/team.jpg");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 30%;
  padding-right: 50px;
  color: #fff;
  text-align: center;
}
.back h1 {
  font-size: 100px;
  font-weight: 400;
}
.back h2 {
  font-weight: 500;
  font-size: 17px;
  text-transform: uppercase;
}
