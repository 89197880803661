/*--------head------------*/
.head {
  padding: 20px 0;
  color: #fff;
}
.logo h1 {
  font-size: 35px;
  font-weight: 900;
}
.logo{
  cursor: pointer;
  color: #fff;
}
.logo:hover{
  color: #1a3975;
}
.head .icon {
  margin-left: 10px;
}
.second{
  display: none;
  transition: 0.3s;
}
.logo:hover .second{
  display: block;
}
/*--------head------------*/
/*--------header------------*/
header {
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 30px;
  border-radius: 10px;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}
header .nav {
  display: flex !important;
}
#jpslogosvg{
  position: absolute;
  width:75px;
  top:0;
  left:45px;
  cursor: pointer;
  transition: 0.3s;
}
header li {
  margin-right: 40px;
}
header ul {
  padding: 30px 20px;
  z-index: 11;
}
header ul li a {
  color: #fff;
  font-weight: 500;
  transition: 0.5s;
}
header ul li a:hover {
  color: #1a3975;
}
.start {
  background-color: #1a3975;
  padding: 30px 70px;
  color: #fff;
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
}
.toggle {
  display: none;
}
/*--------header------------*/
@media screen and (max-width: 768px) {
  .start {
    clip-path: none;
  }
  header {
    margin: 0;
    background-color: #1a3975;
    position: relative;
  }
  header ul.flexSB {
    display: none;
  }
  header ul li {
    margin-bottom: 20px;
  }
  .toggle {
    display: block;
    background: none;
    color: #fff;
    font-size: 30px;
    position: absolute;
    right: 0;
    top: -20px;
  }
  .mobile-nav {
    position: absolute;
    top: 7vh;
    left: 0;
    width: 100%;
    background-color: #1a3975;
  }
}
