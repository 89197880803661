.back_alumni {
    background-image: url("../../../public/images/bakh5.jpg");
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    padding-top: 30%;
    padding-right: 50px;
    color: #fff;
    text-align: center;
  }
  .back_alumni h1 {
    font-size: 100px;
    font-weight: 400;
  }
  .back_alumni h2 {
    font-weight: 500;
    font-size: 17px;
    text-transform: uppercase;
  }