::-webkit-scrollbar{
    display: none;
}
.something{
    background-color: rgba(0, 0, 0, 0.2);
    /* position: absolute; */
    margin: -200px auto;
    padding: 20px;
    width: 600px;
    border-radius: 20px;
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
}