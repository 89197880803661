.newletter {
  background-color: #1a3975;
  padding: 50px 0;
  color: #fff;
}
.newletter .right,
.newletter .left {
  padding-right: 50px;
}
.newletter h1 {
  color: white;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 15px;
}
a{
  color: black;
  list-style: none;
  text-decoration: none;
}
.newletter .right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.newletter input {
  width: 100%;
  padding: 20px;
  border: none;
  outline: none;
  height: 30px;
}
.newletter i {
  padding: 12px;
  color: #1a3975;
  background-color: #fff;
  cursor: pointer;
}
.newletter i:hover{
  color: black;
}
footer {
  background-color: #eeeeee;
}
footer .container {
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 4fr 3fr;
  grid-gap: 30px;
}
@media screen and (max-width: 768px) {
  footer .container {
    grid-template-columns: repeat(2, 1fr);
  }
}
footer .logo span {
  color: #1a3975;
  font-size: 14px;
}
footer .logo p {
  color: grey;
  margin: 30px 0 15px 0;
}
footer .logo .icon {
  background-color: #1a3975;
  color: white;
  margin-right: 10px;
}
footer h3 {
  margin-bottom: 40px;
  font-weight: 500;
}
footer ul li {
  margin-bottom: 20px;
}
footer .link li::before {
  content: "⟶";
  color: #1a3975;
  margin-right: 5px;
}
.link a:hover{
  color:#1a3975;
  font-size: 18px;
}
.link a{
  transition: 0.3s;
}
footer .items {
  margin-bottom: 30px;
}
footer .items img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: cover;
}
footer .items span,
footer .items i {
  font-size: 12px;
  color: #1a3975;
  margin-right: 5px;
  text-transform: capitalize;
}
footer h4 {
  font-weight: 400;
  margin-top: 5px;
}
footer .last ul li {
  display: flex;
  margin-bottom: 50px;
}
footer .last i {
  width: 60px;
  font-size: 20px;
  color: #1a3975;
}
.legal {
  text-align: center;
  padding: 20px 0;
  color: white;
  background-color: black;
}
.legal p {
  font-size: 15px;
}
.legal i {
  color: #1a3975;
}
.behzod{
  color: #3075ff;
  text-decoration: underline;
  transition: 0.3s;
}
.behzod:hover{
  color: #ffffff;
  text-decoration: none;
}
